import { AuthError } from '../api'

export const isInvalidEmail = (e: AuthError | null) => {
  if (!e?.validation?.keys) return false;
  return e?.validation?.keys?.some((v) => v === 'email')
};

export const isInvalidPassword = (e: AuthError | null) => {
  if (!e?.validation?.keys) return false;
  return e?.validation?.keys?.some((v) => v === 'password')
};

export const isUnauthorized = (e: AuthError | null): boolean => {
    return e?.statusCode === 401;
};

export const isUnknownClientError = (e: AuthError | null): boolean => {
  if (!e) return false;
  if(e.status
    && e.status >= 400
    && e.status < 500)
    return true;

  return e && (e?.statusCode >= 400)
    && (e?.statusCode < 500)
    && (e?.statusCode != 401)
    && (e?.statusCode != 409);
};

export const isUnknownServerError = (e: AuthError | null): boolean => {
  if (!e) return false;
  if (e.status && e.status >= 500) return true;
  return e.statusCode >= 500 && e.statusCode < 600;
};

export const isUserExists = (e: AuthError | null): boolean => {
    return Boolean(e && e.statusCode === 409 && e.message === 'user_exists');
};

export const isInvalidPromo = (e: AuthError | null): boolean => {
    return Boolean(e && e.statusCode === 409 && e.message === 'invalid_promo');
};

export const isUnknownEmail = (e: AuthError | null) => {
  if (!e) return false;
  return e.statusCode === 409 && e.message === 'unknown_email';
};

export const isUserNotFound = (e: AuthError | null) => {
  if (!e) return false;
  return e.statusCode === 401 && e.message === 'user_not_found';
};

export const isOrganizationExists = (e: AuthError | null): boolean => {
  return Boolean(e && e.statusCode === 409 && e.message === 'organization_exists');
};