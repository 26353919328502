import React from "react";
import PropTypes from 'prop-types';
import { Function0, Function1, isFunction, isUndefined } from 'lodash';
import { VelocityTransitionGroup } from "velocity-react";

const eyeImage = (
  <svg
    className="password-eye"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 511.999 511.999"
    width="15"
    height="15"
    fill="#8d99a6"
  >
    <path d="M508.745 246.041c-4.574-6.257-113.557-153.206-252.748-153.206S7.818 239.784 3.249 246.035c-4.332 5.936-4.332 13.987 0 19.923 4.569 6.257 113.557 153.206 252.748 153.206s248.174-146.95 252.748-153.201c4.338-5.935 4.338-13.992 0-19.922zM255.997 385.406c-102.529 0-191.33-97.533-217.617-129.418 26.253-31.913 114.868-129.395 217.617-129.395 102.524 0 191.319 97.516 217.617 129.418-26.253 31.912-114.868 129.395-217.617 129.395z" />
    <path d="M255.997 154.725c-55.842 0-101.275 45.433-101.275 101.275s45.433 101.275 101.275 101.275S357.272 311.842 357.272 256s-45.433-101.275-101.275-101.275zm0 168.791c-37.23 0-67.516-30.287-67.516-67.516s30.287-67.516 67.516-67.516 67.516 30.287 67.516 67.516-30.286 67.516-67.516 67.516z" />
  </svg>
);

type InputProps = {
  id: string
  errorText?: string
  showErrorText?: boolean
  tabIndex?: number
  clean?: boolean
  label: React.ReactNode
  text: any
  type?: string
  validator?: Function1<string, boolean>
  onChange?: Function1<string, void>
  noLabel?: boolean
  readOnly?: boolean
  required?: boolean
  onError?: Function0<void>
  onSuccess?: Function0<void>
  autoFocus?: boolean
  password?: boolean
  placeholder?: string
  onClick?: Function0<void>
  style?: any
}

export class Input extends React.Component<InputProps> {
  private inputRef: React.RefObject<HTMLInputElement> = React.createRef();

  constructor(props: InputProps) {
    super(props);
  }

  componentDidMount() {
    this.handleErrors(this.props.text);
  }

  handleEyeClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    // @ts-ignore
    if (!event.target.classList.contains("password-eye")) return;
    const field = this.inputRef.current;
    if (!field) return;
    event.currentTarget.classList.toggle("active");

    if (field.type === "password") {
      field.type = "text";
    } else {
      field.type = "password";
    }
  }

  handleErrors = (text: string) => {
    const { required, validator, onError, onSuccess } = this.props;

    let hasError = isFunction(validator) ? validator(text) === false : false;
    if (required && !text) hasError = true;

    if (hasError) {
      if (isFunction(onError)) onError();
    } else {
      if (isFunction(onSuccess)) onSuccess();
    }
  }

  render() {
    const {
      label, id, text = "", type, onChange, validator, onClick, style = {}, noLabel, required, readOnly, autoFocus,
      password, clean, tabIndex, showErrorText, errorText, placeholder
    } = this.props;

    let hasError = isFunction(validator) ? validator(text) === false : false;
    if (required && !text) hasError = true;

    let className =
      isFunction(validator) || required
        ? hasError
          ? " has-error"
          : " has-success"
        : "";

    const inputElement = (
      <input
        ref={this.inputRef}
        type={password ? "password" : type ? type : "text"}
        id={id}
        readOnly={readOnly}
        autoFocus={autoFocus}
        tabIndex={tabIndex}
        onChange={e => {
          e.preventDefault();
          this.handleErrors(e.target.value);
          if (onChange) {
            onChange(e.target.value);
          }
        }}
        style={style}
        onClick={onClick}
        className={isUndefined(text) || text == "" ? " empty" : ""}
        value={text}
        placeholder={placeholder || ""}
      />
    );

    return (
      <div className={`form-item ${clean ? "" : className}`}>
        {!noLabel && <label htmlFor={id}>{label}</label>}

        {type === "password" ? (
          <div className="password-input-wrapper" onClick={this.handleEyeClick}>
            {inputElement}
            {eyeImage}
          </div>
        ) : (
          inputElement
        )}

        <VelocityTransitionGroup
          enter={{ animation: "slideDown" }}
          leave={{ animation: "slideUp" }}
        >
          {showErrorText ? (
            <div className="qpos-input-error">{errorText}</div>
          ) : null}
        </VelocityTransitionGroup>
      </div>
    );
  }
}

// @ts-ignore
Input.defaultProp = {
  tabIndex: -1,
  showErrorText: false
};

// @ts-ignore
Input.propTypes = {
  errorText: PropTypes.string,
  showErrorText: PropTypes.bool,
  tabIndex: PropTypes.number,
  clean: PropTypes.bool,
  label: PropTypes.string,
  text: PropTypes.any,
  type: PropTypes.string,
  validator: PropTypes.func,
  onChange: PropTypes.func,
  noLabel: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  autoFocus: PropTypes.bool,
  password: PropTypes.bool
};