import React from "react";
import PropTypes from 'prop-types';
import { Function1 } from 'lodash';
import utils from "../utils";

type RadioButtonProps = {
  className?: string
  label: string
  checked: boolean
  value: string
  onChange: Function1<string, void>
}

export class RadioButton extends React.Component<RadioButtonProps> {
  render() {
    const { label, checked, onChange, value, className } = this.props;
    const key = utils.htmlId();

    return (
      <div className={"radio-custom radio-primary" + (className ? " " + className : "")}>
        <input
          type="radio"
          id={key}
          checked={checked}
          value={value}
          onChange={(e) => {
          if (onChange) {
            onChange(e.target.value);
          }
        }}/>
        <label htmlFor={key}>
          {label}
        </label>
      </div>
    )
  }
}

// @ts-ignore
RadioButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func
};