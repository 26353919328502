import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { LoginPage } from './pages/LoginPage'
import { RecoverPage } from './pages/RecoverPage'
import { RegisterPage } from './pages/RegisterCustomerPage'
import { RegisterPartner } from './pages/RegisterPartnerPage'

export const Routes = () => (
  <Switch>
    <Route path='/login' component={LoginPage}/>
    <Route path='/register' component={RegisterPage}/>
    <Route path='/recover' component={RecoverPage}/>
    <Route path='/register-partner' component={RegisterPartner}/>
    <Route path='*'>
      <Redirect to='/login'/>
    </Route>
  </Switch>
)