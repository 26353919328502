import React from 'react';
import '../styles/tariff-banner.scss';
import { Link } from 'react-router-dom';
import i18n from '../i18n';

const TRUE_SIGN_LINK = 'https://markirovka.ru/knowledge/tovarnye-gruppy/tabachniye-izdeliya/kak-poluchit-klyuch-dostupa-dlya-razreshitelnogo-rezhima-proverok-na-kkt';
const ORG_LINK = 'https://kassatka.online/organizations';
const DOC_LINK = 'https://wiki.kassatka.online/docs/onlajn-proverka-kodov-markirovki-v-chestnom-znake-razreshitelnyj-rezhim/';
const aStyles = {
  color: '#8d99a6',
  textDecoration: 'underline'
};

type IProps = {
  onShowNewEmail: () => void
  isShowChangeEmailBanner: boolean
}

export const InfoBanner: React.FC<IProps> = (props) => {
  const {
    isShowChangeEmailBanner,
    onShowNewEmail,
  } = props;

  if (!isShowChangeEmailBanner) return null;

  return <div className='tariff-banner'>
    <div className='tariff-banner__header'>Уважаемый клиент!</div>
    <div className='tariff-banner__content'>
      <p>
        С 1 января 2025 в РФ вступит в силу закон о запрете регистрации и авторизации на отечественных сайтах через иностранные сервисы. Если для авторизации Вы используете иностранный email пожалуйста смените его.
      </p>
      {/*<p>*/}
      {/*  Разрешительный режим предусмотрен при продаже следующих категорий маркируемой продукции:*/}
      {/*  <ul style={{ paddingLeft: '12px' }}>*/}
      {/*    <li>табачных изделий;</li>*/}
      {/*    <li>пива и слабоалкогольных напитков;</li>*/}
      {/*    <li>«молочки»;</li>*/}
      {/*    <li>упакованной воды;</li>*/}
      {/*    <li>антисептических средств;</li>*/}
      {/*    <li>биологически активных добавок (БАДов);</li>*/}
      {/*    <li>шин и автопокрышек;</li>*/}
      {/*    <li>товаров легпрома;</li>*/}
      {/*    <li>обуви;</li>*/}
      {/*    <li>фотоаппаратов и ламп-вспышек;</li>*/}
      {/*    <li>духов и туалетной воды;</li>*/}
      {/*    <li>безалкогольных напитков.</li>*/}
      {/*  </ul>*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Для включения разрешительного режима необходимо:<br/>*/}
      {/*  <ul style={{ paddingLeft: '10px' }}>*/}
      {/*    <li>Обновить Программное Обеспечение на ККТ «Кассатка» до версии 2362.</li>*/}
      {/*    <li><a style={aStyles} href={TRUE_SIGN_LINK} target="_blank" rel="noopener noreferrer">В личном кабинете «Честный*/}
      {/*    знак»</a> получить токен (ключ доступа для разрешительного режима.</li>*/}
      {/*    <li><a style={aStyles} href={ORG_LINK} target="_blank" rel="noopener noreferrer">В Личном кабинете «Кассатка»</a> в*/}
      {/*    разделе «Организации» указать токен из*/}
      {/*    ЛК «Честный Знак».</li>*/}
      {/*  </ul>*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  <a style={aStyles} href={DOC_LINK} target="_blank" rel="noopener noreferrer">Руководство пользователя</a>*/}
      {/*</p>*/}
      <p style={{ textAlign: 'center' }}>
        <button className="change-button" type='button' onClick={onShowNewEmail}>
          Сменить email
        </button>
      </p>
    </div>


  </div>
}