import React from 'react'
import { Link } from 'react-router-dom'
import { VelocityTransitionGroup } from 'velocity-react'
import { Function0, Function1 } from 'lodash'

import * as Components from '../../components'
import * as validators from '../../utils/validators'
import * as i18n from '../../i18n'

type RecoverFormProps = {
  recover: Function0<void>
  onChangeEmail: Function1<string, void>
  isRecoverEmailSent: boolean
  isUnknownEmail: boolean
  isUnknownClientError: boolean
  isUnknownServerError: boolean
  submitIsEnabled: boolean
  isUserNotFound: boolean
  email: string
}

export const RecoverForm: React.FC<RecoverFormProps> = (props) => {
  const {
    recover, isRecoverEmailSent, isUnknownEmail, isUnknownClientError, isUnknownServerError, onChangeEmail, isUserNotFound,
    submitIsEnabled, email
  } = props;

  return (
    <form
      className="login-form"
      onSubmit={e => {
        e.preventDefault();
        recover();
      }}
    >
      <VelocityTransitionGroup
        enter={{ animation: "slideDown" }}
        leave={{ animation: "slideUp" }}
      >
        {isRecoverEmailSent && (
          <Link to='/login' className='qpos-login-info'>
            {i18n.messages.recoverEmailSent}
          </Link>
        )}

        {(isUnknownEmail || isUserNotFound) && (
          <div className="qpos-login-alert">
            {i18n.messages.unknownEmail}
          </div>
        )}

        {isUnknownClientError && (
          <div className="qpos-login-alert">
            {i18n.messages.unknownClientError}
          </div>
        )}

        {isUnknownServerError && (
          <div className="qpos-login-alert">
            {i18n.messages.unknownServerError}
          </div>
        )}
      </VelocityTransitionGroup>

      <Components.Input
        id="username"
        label={i18n.labels.email}
        onChange={(email: string) => onChangeEmail(email)}
        text={email}
        autoFocus
        required
        tabIndex={1}
        validator={validators.isEmail}
      />

      {submitIsEnabled ? (
        <Components.Button
          onClick={() => recover()}
          label={i18n.buttons.recover}
          submit
          noIcon
          disabled={!submitIsEnabled}
          noMargin
          className="brand-btn brand-btn-primary"
        />
      ) : (
        <Components.Button
          label={i18n.buttons.recover}
          noIcon
          noMargin
          className="brand-btn brand-btn-primary disabled"
        />
      )}
    </form>
  )
}

export const Links = () => {
  return (
    <>
      <p>
        {i18n.labels.rememberedPassword}{" "}
        <Link className="login-link" to="/login">
          {i18n.labels.login}
        </Link>
      </p>
      <p>
        {i18n.labels.isNoAccount}{" "}
        <Link className="register-link" to="/register">
          {i18n.labels.register}
        </Link>
      </p>
    </>
  )
}