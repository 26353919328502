import 'core-js';
import 'regenerator-runtime/runtime.js';
import React from 'react';
import ReactDOM from 'react-dom';
// import './styles/partner.scss';
import { App } from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('app')
);

const elem = document.getElementById("styles");
// @ts-ignore
elem.parentNode.removeChild(elem);