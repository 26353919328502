export const labels = {
    partnerNetworkMode: 'Режим партнерской сети',
    mode2: 'Классический',
    mode3: 'Сообщество',
    email: 'Email',
    password: 'Пароль',
    inn: 'ИНН организации / ИП',
    closeBanner: 'Больше не показывать',
    rememberMe: 'Запомнить меня',
    isNoAccount: 'Нет аккаунта?',
    register: 'Регистрация',
    login: 'Вход',
    recover: 'Восстановить',
    promoCode: 'Промокод (если есть)',
    organizationName: 'Название организации',
    address: 'Адрес',
    ogrn: 'ОГРН',
    alreadyRegistered: 'Уже зарегистрировались?',
    forgetPassword: 'Забыли пароль?',
    rememberedPassword: 'Вспомнили пароль?',
    partnerRegister: 'Стать партнером',
    accountRegister: 'Стать клиентом',
    offer: 'Ознакомиться с офертой'
};

export const buttons = {
    login: 'Вход',
    register: 'Регистрация',
    partnerRegister: 'Стать партнером',
    recover: 'Восстановить'
};

export const messages = {
    invalidEmailOrPassword: "E-mail или пароль указаны неверно",
    invalidEmail: "Неверно указан e-mail",
    invalidPassword: "Неверно указан пароль (минимум 6 символов)",
    unknownClientError: "Неизвестная ошибка клиента",
    unknownServerError: "Неизвестная ошибка сервера",
    userExists: "Пользователь с таким e-mail уже существует",
    invalidPromo: "Неизвестный промокод",
    unknownEmail: "Неизвестный e-mail адрес",
    recoverEmailSent: "Письмо с новым паролем отправлено",
    isOrganizationExists: "Организация с таким ИНН уже существует",
};

export default {labels, buttons, messages};
