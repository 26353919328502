import React from "react";
import PropTypes from 'prop-types';
import { Function0 } from 'lodash';

type ButtonProps = {
  label?: string
  icon?: string
  noMargin?: boolean
  onClick?: Function0<void>
  className?: string
  disabled?: boolean
  noIcon?: boolean
  submit?: boolean
  right?: boolean
  style?: any
}

export const Button: React.FC<ButtonProps> = (props) => {
  const { noMargin, onClick, style, disabled, className, icon, noIcon, submit, right, label } = props;

  let defaultClass = className || "btn-primary";
  let defaultIcon = icon || "md-plus";

  if (disabled) defaultClass += " disabled";
  if (right) defaultClass += " pull-right";

  const buttonStyle = {
    ...style,
    ...(noMargin
      ? {}
      : {
          marginBottom: "16px",
          marginTop: "16px"
        })
  };

  return (
    <button
      className={"btn " + defaultClass}
      type={submit ? "submit" : "button"}
      tabIndex={-1}
      onClick={e => {
        e.preventDefault();
        if (onClick) onClick();
      }}
      style={buttonStyle}
    >
      {noIcon ? null : (
        <i className={"icon " + defaultIcon} aria-hidden="true" />
      )}
      {label}
    </button>
  );
}

Button.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  noMargin: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  noIcon: PropTypes.bool,
  submit: PropTypes.bool
};