import React from "react";
import cookie from 'js-cookie'

import * as Components from "../../components";
import * as validators from "../../utils/validators";
import { AuthError, isResponseWithAuthError } from '../../api'
import * as selectors from '../../utils/errorDecoders'
import { Links } from './_Links'
import { login } from './_api'
import { InfoBanner } from '../../components/InfoBanner';
import { VelocityTransitionGroup } from 'velocity-react';
import i18n from '../../i18n';
// import { TariffBanner } from '../../components/TariffBanner';

type LoginProps = {

}

type LoginState = {
  email: string
  password: string
  isRemember: boolean
  error: AuthError | null
  newEmail: string | null
  newEmailRepeat: string | null
}

export class LoginPage extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isRemember: false,
      error: null,
      newEmail: null,
      newEmailRepeat: null,
    };
  }

  onLogin = async () => {
    const { email, password, isRemember, newEmail } = this.state;
    const submitIsEnabled = validators.isEmail(this.state.email) && validators.isPassword(this.state.password);

    if (!submitIsEnabled) return;

    try {
      this.setState({ error: null });
      const result = await login({
        email,
        password,
        new_email: newEmail ? newEmail : undefined
      })
      // show discount modal
      localStorage.removeItem('tariffDiscountModal');
      if(isRemember) {
        cookie.set('TOKEN', result.token, { expires: 7 })
        cookie.set('TYPE', result.type, { expires: 7 })
        if (result.brand) {
          cookie.set('BRAND', result.brand, { expires: 7 })
        }
      } else {
        cookie.set('TOKEN', result.token)
        cookie.set('TYPE', result.type)
        if(result.brand) {
          cookie.set('BRAND', result.brand);
        }
      }

      document.location.href = "/";
    } catch (e: any) {
      if (isResponseWithAuthError(e)) {
        this.setState({ error: e.authError })
      }
    } finally {
      this.setState({ password: '' });
    }
  }

  onShowNewEmail= () => {
    if (this.state.newEmail === null) this.setState({ newEmail: '' })
  }

  render() {
    const { email, password, isRemember, error, newEmail, newEmailRepeat } = this.state;
    const isInvalidEmail = selectors.isInvalidEmail(error);
    const isInvalidPassword = selectors.isInvalidPassword(error);
    const isUnauthorized = selectors.isUnauthorized(error);
    const isUnknownClientError = selectors.isUnknownClientError(error);
    const isUnknownServerError = selectors.isUnknownServerError(error);
    const submitIsEnabled =
      validators.isEmail(this.state.email) &&
      validators.isPassword(this.state.password);

    const isShowChangeEmailBanner = Boolean(email && validators.isEmail(email) && !validators.isRuEmail(email));

    const isNewEmailValid = Boolean(newEmail) ? validators.isRuEmail(newEmail) : true;
    const isNewEmailRepeatValid = Boolean(newEmail) ? validators.isRuEmail(newEmailRepeat) && !!newEmail && newEmailRepeat === newEmail : true;

    return (
      <div className="app">
        <div className="panel-body max-w-600">
          <Components.Brand/>
          <form
            className="login-form"
            onSubmit={e => {
              e.preventDefault();
              this.onLogin();
            }}
          >
            <VelocityTransitionGroup
              enter={{ animation: "slideDown" }}
              leave={{ animation: "slideUp" }}
            >
              {isUnauthorized && (
                <div className="qpos-login-alert">
                  {i18n.messages.invalidEmailOrPassword}
                </div>
              )}

              {isUnknownClientError && (
                <div className="qpos-login-alert">
                  {i18n.messages.unknownClientError}
                </div>
              )}

              {isUnknownServerError && (
                <div className="qpos-login-alert">
                  {i18n.messages.unknownServerError}
                </div>
              )}
            </VelocityTransitionGroup>

            <Components.Input
              id="username"
              label={i18n.labels.email}
              onChange={(email: string) => this.setState({ email })}
              text={email}
              autoFocus
              required
              showErrorText={isInvalidEmail}
              errorText={i18n.messages.invalidEmail}
              tabIndex={1}
              validator={validators.isEmail}
              placeholder="Введите email"
            />

            <Components.Input
              type="password"
              id="password"
              label={i18n.labels.password}
              onChange={(password: string) => this.setState({ password })}
              text={password}
              showErrorText={isInvalidPassword}
              errorText={i18n.messages.invalidPassword}
              required
              tabIndex={2}
              validator={validators.isPassword}
              placeholder="Введите пароль"
            />
            {newEmail !== null && <>
              <Components.Input
                id="newEmail"
                label='Новый email'
                onChange={(newEmail: string | null) => this.setState({ newEmail })}
                text={newEmail}
                showErrorText={newEmail ? !validators.isRuEmail(newEmail) : false}
                errorText='Только зона .рф или .ru'
                validator={newEmail ? validators.isRuEmail : undefined}
                placeholder="Введите новый email"
              />
              <Components.Input
                id="newEmailRepeat"
                label='Повторите новый email'
                onChange={(newEmailRepeat: string | null) => this.setState({ newEmailRepeat })}
                text={newEmailRepeat}
                showErrorText={!!newEmail && newEmailRepeat !== newEmail}
                errorText='email не совпадает'
                validator={newEmail ? (v: string) => {
                  return validators.isRuEmail(v) && !!newEmail && newEmailRepeat === newEmail
                } : undefined}
                placeholder="Введите новый email"
              />
            </>}

            <div className="form-item">
              <Components.Checkbox
                className="brand-checkbox"
                label={i18n.labels.rememberMe}
                checked={isRemember}
                tabIndex={3}
                onChange={(isRemember: boolean) => this.setState({ isRemember })}
              />
            </div>

            <Components.Button
              className="brand-btn brand-btn-primary login-button"
              noMargin
              label={i18n.buttons.login}
              noIcon
              onClick={submitIsEnabled ? this.onLogin : undefined}
              submit={submitIsEnabled}
              disabled={!submitIsEnabled || !isNewEmailValid || !isNewEmailRepeatValid}
            />
          </form>

          <InfoBanner
            onShowNewEmail={this.onShowNewEmail}
            isShowChangeEmailBanner={isShowChangeEmailBanner}
          />
          {/*<TariffBanner />*/}
          <Links />
        </div>
      </div>
    );
  }
}