import { Routes } from './Routes';
import './styles/auth.scss';

import React from 'react';
import { BrowserRouter } from 'react-router-dom';

export const App = () => (
  <BrowserRouter>
    <div>
      <Routes />
    </div>
  </BrowserRouter>
);