import React from "react";
import PropTypes from 'prop-types';
import { Function1 } from 'lodash';
import utils from "../utils";

type CheckboxProps = {
  checked: boolean
  onChange: Function1<boolean, void>
  tabIndex?: number
  label?: string
  className?: string
}

type CheckboxState = {
  checked: boolean
}

export class Checkbox extends React.Component<CheckboxProps, CheckboxState> {
  constructor(props: CheckboxProps) {
    super(props);
    this.state = {
      checked: this.props.checked
    };
  }

  static getDerivedStateFromProps(props: CheckboxProps, state: CheckboxState): CheckboxState | null {
    return props.checked === state.checked
      ? null
      : {
        ...state,
        checked: props.checked
      }
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;

    const checked = event.target.checked;

    this.setState(
      {
        checked: checked
      },
      () => {
        if (onChange) {
          onChange(checked);
        }
      }
    );
  };

  render() {
    const key = utils.htmlId();
    const { className, label, tabIndex } = this.props;

    return (
      <div className={className}>
        <input
          type="checkbox"
          className="visually-hidden"
          id={key}
          checked={this.state.checked}
          tabIndex={tabIndex}
          onChange={this.handleChange}
        />

        <label htmlFor={key}>{label}</label>
      </div>
    );
  }
}

// @ts-ignore
Checkbox.defaultProps = {
  tabIndex: -1,
  checked: false,
  className: "checkbox-custom checkbox-primary"
};

// @ts-ignore
Checkbox.propTypes = {
  tabIndex: PropTypes.number,
  label: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func
};