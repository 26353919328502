import React, { Component } from "react";
import cookie from 'js-cookie'

import * as Components from "../../components";
import * as validators from "../../utils/validators";
import { BottomLinks, RegisterPartnerForm } from './_Components'
import { AuthError, isResponseWithAuthError } from '../../api'
import { registerPartnerApi } from "./_api";
import * as selectors from '../../utils/errorDecoders'

type RegisterPartnerProps = {

}

type RegisterPartnerState = {
  email: string
  error: AuthError | null
}

export class RegisterPartner extends Component<RegisterPartnerProps, RegisterPartnerState> {
  constructor(props: RegisterPartnerProps) {
    super(props);
    this.state = {
      email: '',
      error: null
    };
  }

  registerPartner = async () => {
    const submitIsEnabled = validators.isEmail(this.state.email);
    if (!submitIsEnabled) return false;

    try {
      this.setState({ error: null });
      const result = await registerPartnerApi({
        email: this.state.email,
        become_partner: true,
        partner_network_mode: 'mode2',
      })

      cookie.set('TOKEN', result.token);
      cookie.set('TYPE', result.type);
      if(result.brand) {
        cookie.set('BRAND', result.brand);
      }
      document.location.href = "/";
    } catch (e: any) {
      if (isResponseWithAuthError(e)) {
        this.setState({ error: e.authError })
      }
    } finally {
      this.setState({ email: '' });
    }
  }

  render() {
    const { email, error } = this.state;
    const submitIsEnabled = validators.isEmail(this.state.email);
    const isUserExists = selectors.isUserExists(error);
    const isUnknownClientError = selectors.isUnknownClientError(error);
    const isUnknownServerError = selectors.isUnknownServerError(error);

    return (
      <div className="app">
        <div className="panel-body max-w-600">
          <Components.Brand />
          <RegisterPartnerForm
            isUserExists={isUserExists}
            isUnknownClientError={isUnknownClientError}
            isUnknownServerError={isUnknownServerError}
            email={email}
            registerPartner={this.registerPartner}
            submitIsEnabled={submitIsEnabled}
            onChangeEmail={(email: string) => this.setState({ email })}
          />
          <BottomLinks />
        </div>
      </div>
    );
  }
}