import cookies from 'js-cookie';

type Headers = {
  'Content-Type': string,
  'Accept': string
}

function getHeaders(): Headers {
  return {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  };
}

function getBaseUrl() {
  if (process.env.NODE_ENV === 'production') {
    return cookies.get('DEV_BASEURL')
      ? `${cookies.get('DEV_BASEURL') as string}/auth`
      : 'https://api.kassatka.online/auth';
  } else {
    // return 'https://api.kassatka.online/auth';
    return 'https://api.stage.k8s.kassatka.online/auth';
    // return 'http://localhost:30108/web/v1';
  }
}

export default {
  headers: getHeaders(),
  baseUrl: getBaseUrl(),
};

//   login: `${baseUrl}/auth/login`,
//   register: `${baseUrl}/auth/register`,
//   recover: `${baseUrl}/auth/recover`,
//   me: `${baseUrl}/auth/info`