export type CountryCode = 'RU' | 'AB'


export const isEmail = (email?: string): boolean => {
  if (!email) return false;
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z|(рф))]{2,}))$/;
  return re.test(email);
};

export const isRuEmail = (email?: string | null): boolean => {
  if (!email) return false;
  if (!isEmail(email)) return false;
  return /\.ru$|\.рф$/.test(email);
};

export const isInn = (countryCode: CountryCode | null, inn: string): boolean => {
  if (countryCode === 'AB') return isInnAbh(inn);
  if (countryCode === 'RU') return isInnRu(inn);
  return false;
};

// export const isInnRu = (inn: string): boolean => isPositiveNumber(inn) && (inn.length === 10 || inn.length === 12);

export const isInnAbh = (inn: string): boolean => isPositiveNumber(inn) && (inn.length === 8);

export const isOgrn = (countryCode: CountryCode | null, ogrn: string): boolean => {
  if (countryCode === 'AB') return true;
  if (countryCode === 'RU')  return isPositiveNumber(ogrn) && (ogrn.length == 13 || ogrn.length == 15);
  return false;
};

export const isPositiveNumber = (value: string | number): boolean => !(value && (isNaN(Number(value)) || value < 0));

export const isPassword = (password?: string): boolean => {
  if (!password) return false;
  return password.length >= 6;
};

export const isRequiredV2 = (value: string): boolean => !!value;

export const isInnRu = (i: string): boolean => {
  if (!i) return false;
  if (i.match(/\D/)) return false;

  const inn = i.match(/(\d)/g);
  if (!inn) return false;
  if (/^00/.test(i)) return false;

  if (inn.length === 10) {
    // @ts-ignore
    return (inn[9] === String(((2 * inn[0] + 4 * inn[1] + 10 * inn[2] + 3 * inn[3] + 5 * inn[4] + 9 * inn[5] + 4 * inn[6] + 6 * inn[7] + 8 * inn[8]) % 11) % 10));
  }

  if (inn.length === 12) {
    // @ts-ignore
    return (inn[10] === String(((7 * inn[0] + 2 * inn[1] + 4 * inn[2] + 10 * inn[3] + 3 * inn[4] + 5 * inn[5] + 9 * inn[6] + 4 * inn[7] + 6 * inn[8] + 8 * inn[9]) % 11) % 10) && inn[11] === String(((3 * inn[0] + 7 * inn[1] + 2 * inn[2] + 4 * inn[3] + 10 * inn[4] + 3 * inn[5] + 5 * inn[6] + 9 * inn[7] + 4 * inn[8] + 6 * inn[9] + 8 * inn[10]) % 11) % 10));
  }
  return false
};