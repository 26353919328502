import { postFetch } from '../../api'

type RecoverParams = {
  email: string
}

type RecoverResult = {
  info_code: 'email_sent'
}

export async function recoverApi(params: RecoverParams): Promise<RecoverResult> {
  return await postFetch<RecoverParams, RecoverResult>('/recover', params)
}