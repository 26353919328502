import React from 'react';
import fetch from 'isomorphic-fetch';
import cookie from 'js-cookie';
import queryString from 'query-string';

import * as Components from '../../components';
import * as validators from '../../utils/validators';
import { BottomLinks, RegisterCustonerForm, TopLinks } from './_Components'
import * as selectors from '../../utils/errorDecoders'
import { AuthError, isResponseWithAuthError } from '../../api'
import { registerApi } from './_api'
import { CountryCode, isInnAbh, isInnRu } from '../../utils/validators';

type RegisterPageProps = {
  location?: {
    query?: {
      promo?: string
      email?: string
    }
  }
}

type RegisterPageState = {
  email: string
  promo: string
  isReadOnlyPromo: boolean
  inn: string
  organizationName: string
  address: string
  ogrn: string
  countryCode: CountryCode | null
  error: AuthError | null
  loading: boolean
}

export class RegisterPage extends React.Component<RegisterPageProps, RegisterPageState> {
  timer: NodeJS.Timeout | null = null;
  constructor(props: RegisterPageProps) {
    super(props);

    const promoFromQuery = props.location?.query?.promo || '';
    const emailFromQuery = props.location?.query?.email || '';

    this.state = {
      promo: promoFromQuery.toUpperCase(),
      isReadOnlyPromo: Boolean(promoFromQuery),
      inn: '',
      organizationName: '',
      address: '',
      ogrn: '',
      countryCode: null,
      email: emailFromQuery,
      error: null,
      loading: false,
    };
  }

  private readonly _register = async () => {
    const { countryCode } = this.state;
    if (!countryCode) return;

    const submitIsEnabled =
      validators.isEmail(this.state.email) &&
      validators.isInn(countryCode, this.state.inn) &&
      validators.isOgrn(countryCode, this.state.ogrn) &&
      validators.isRequiredV2(this.state.address) &&
      validators.isRequiredV2(this.state.organizationName);

    if (!submitIsEnabled) return false;

    try {
      this.setState({ error: null, loading: true });
      const result = await registerApi({
          email: this.state.email,
          inn: this.state.inn,
          ogrn: this.state.ogrn,
          address: this.state.address,
          organizationName: this.state.organizationName,
          promo: this.state.promo,
          countryCode: countryCode,
      })

      cookie.set('TOKEN', result.token);
      cookie.set('TYPE', result.type);
      if(result.brand) {
        cookie.set('BRAND', result.brand);
      }
      document.location.href = '/';
    } catch (e: any) {
      if (isResponseWithAuthError(e)) {
        this.setState({ error: e.authError })
      }
    } finally {
      this.setState({ error: null, loading: false });
    }
  }

  private readonly _getDadata = (value: string) => {
    try {
      const { countryCode } = this.state;
      if (countryCode !== 'RU') return;

      const query = queryString.stringify({ query: value });
      const baseUrl = 'https://api.kassatka.online'; // stage not work
      fetch(`${baseUrl}/dadata/api/Suggestions/Party?${query}`)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            const error = new Error(response.statusText);
            // @ts-ignore
            error.response = response;
            throw error;
          }
        })
        .then(body => {
          const suggestions = body && body.suggestions;

          if (!Array.isArray(suggestions) || suggestions.length === 0) {
            return;
          }

          const exactlySuggetion = suggestions.find((v) => v.data.inn === value);
          if (!exactlySuggetion) {
            return;
          }

          this.setState({
            organizationName: exactlySuggetion.data.name && exactlySuggetion.data.name.short_with_opf || '',
            address: exactlySuggetion.data.address && exactlySuggetion.data.address.value || '',
            ogrn: exactlySuggetion.data.ogrn,
          })
        })
        .catch((e) => {
          console.error(e);
        });
    } catch (e) {
      console.error(e);
    }
  }

  private readonly _onChangeInn = (value: string) => {
    const inn = value.replace(/\D/g, '');
    const countryCodeAbh = isInnAbh(inn) && 'AB';
    const countryCodeRu = isInnRu(inn) && 'RU';

    if (countryCodeAbh) {
      this.timer = setTimeout(() => {
        this.setState({ countryCode: 'AB' })
      }, 1500);
    } else {
      this.timer && clearTimeout(this.timer)
    }

    this.setState({
      inn,
      countryCode: countryCodeRu || null,
    }, () => {
      this._getDadata(inn);
    });
  }

  render() {
    const { email, inn, ogrn, address, organizationName, promo, isReadOnlyPromo, error, countryCode, loading } = this.state;

    const submitIsEnabled = validators.isEmail(email)
      && validators.isInn(countryCode, inn)
      && validators.isOgrn(countryCode, ogrn)
      && validators.isRequiredV2(address)
      && validators.isRequiredV2(organizationName);

    const isUserExists = selectors.isUserExists(error);
    const isInvalidPromo = selectors.isInvalidPromo(error);
    const isUnknownClientError = selectors.isUnknownClientError(error);
    const isUnknownServerError = selectors.isUnknownServerError(error);
    const isOrganizationExists = selectors.isOrganizationExists(error);

    return (
      <div className='app'>
        <div className='panel-body max-w-600'>
          <Components.Brand />
          <TopLinks
            isUserExists={isUserExists}
            isInvalidPromo={isInvalidPromo}
            isUnknownClientError={isUnknownClientError}
            isOrganizationExists={isOrganizationExists}
            isUnknownServerError={isUnknownServerError}
          />
          <RegisterCustonerForm
            register={this._register}
            onChangeEmail={(email: string) => this.setState({ email })}
            onChangePromo={(promo: string) => this.setState({ promo })}
            onChangeOrganizationName={(organizationName: string) => this.setState({ organizationName })}
            onChangeAddress={(address: string) => this.setState({ address })}
            onChangeOgrn={(ogrn: string) => this.setState({ ogrn })}
            onChangeInn={this._onChangeInn}
            showInnFields={Boolean(countryCode)}
            isReadOnlyPromo={isReadOnlyPromo}
            submitIsEnabled={submitIsEnabled && !loading}
            email={email}
            inn={inn}
            promo={promo}
            organizationName={organizationName}
            address={address}
            ogrn={ogrn}
            countryCode={countryCode}
          />
          <BottomLinks />
        </div>
      </div>
    );
  }
}