import { postFetch } from '../../api'

type RegisterCustomerParams = {
  email: string
  promo: string
  inn: string
  ogrn: string
  address: string
  organizationName: string
  countryCode: string
}

type RegisterCustomerResult = {
  token: string
  type: string
  brand?: string
}

export async function registerApi(params: RegisterCustomerParams): Promise<RegisterCustomerResult> {
  return await postFetch<RegisterCustomerParams, RegisterCustomerResult>('/register', params)
}