import React from 'react'
import { Link } from 'react-router-dom'
import { Function0, Function1 } from 'lodash'
import { VelocityTransitionGroup } from 'velocity-react'

import * as Components from '../../components'
import * as validators from '../../utils/validators'
import { CountryCode } from '../../utils/validators';
import * as i18n from '../../i18n'

type RegisterCustonerFormProps = {
  register: Function0<void>
  onChangeEmail: Function1<string, void>
  onChangePromo: Function1<string, void>
  onChangeOrganizationName: Function1<string, void>
  onChangeAddress: Function1<string, void>
  onChangeOgrn: Function1<string, void>
  onChangeInn: Function1<string, void>
  showInnFields: boolean
  isReadOnlyPromo: boolean
  submitIsEnabled: boolean
  email: string
  inn: string
  promo: string
  organizationName: string
  address: string
  ogrn: string
  countryCode: CountryCode | null
}

export const RegisterCustonerForm: React.FC<RegisterCustonerFormProps> = (props) => {
  const {
    register, email, onChangeEmail, onChangePromo, isReadOnlyPromo, promo, inn, showInnFields, onChangeOrganizationName,
    organizationName, address, ogrn, submitIsEnabled, onChangeAddress, onChangeOgrn, onChangeInn, countryCode
  } = props;

  return (
    <form
      className="login-form"
      onSubmit={e => {
        e.preventDefault();
        register();
      }}
    >
      <Components.Input
        id="username"
        label={i18n.labels.email}
        onChange={onChangeEmail}
        text={email}
        autoFocus
        required
        validator={validators.isEmail}
      />

      <Components.Input
        id="inn"
        label={<div style={{ clear: 'both' }}>
          <div style={{ float: 'left' }}>{i18n.labels.inn}</div>
          <div style={{ float: 'right' }}>{countryCode || ''}</div>
        </div>}
        onChange={onChangeInn}
        text={inn}
        required
        validator={(v) => validators.isInn(countryCode, v)}
      />

      <Components.Input
        id="promocode"
        label={i18n.labels.promoCode}
        onChange={onChangePromo}
        text={promo}
        readOnly={isReadOnlyPromo}
      />

      {showInnFields && (
        <div>
          <Components.Input
            id="organizationName"
            label={i18n.labels.organizationName}
            onChange={onChangeOrganizationName}
            text={organizationName}
            required
            validator={validators.isRequiredV2}
          />

          <Components.Input
            id="address"
            label={i18n.labels.address}
            onChange={onChangeAddress}
            text={address}
            required
            validator={validators.isRequiredV2}
          />

          <Components.Input
            id="ogrn"
            label={i18n.labels.ogrn}
            onChange={onChangeOgrn}
            text={ogrn}
            validator={(v) => validators.isOgrn(countryCode, v)}
          />
        </div>
      )}

      {submitIsEnabled ? (
        <Components.Button
          className="brand-btn brand-btn-primary"
          onClick={() => register()}
          label={i18n.buttons.register}
          submit
          noIcon
          noMargin
        />
      ) : (
        <Components.Button
          className="brand-btn brand-btn-primary disabled"
          label={i18n.buttons.register}
          noIcon
          noMargin
        />
      )}
    </form>
  )
}

export const BottomLinks = () => {
  return (
    <>
      <p>
        <Link className="register-partner-link" to="/register-partner">
          {i18n.labels.partnerRegister}
        </Link>
      </p>
      <p>
        {i18n.labels.alreadyRegistered}{" "}
        <Link className="login-link" to="/login">
          {i18n.labels.login}
        </Link>
      </p>

      <p>
        Нажимая кнопку «Регистрация», вы соглашаетесь с условиями &nbsp;
        <a
          href="https://reports.kassatka.online/offer/offer.pdf"
          target="_blank"
          className="offer-link"
        >
          оферты
        </a>
      </p>
    </>
  )
}

type TopLinksProps = {
  isUserExists: boolean
  isInvalidPromo: boolean
  isUnknownClientError: boolean
  isOrganizationExists: boolean
  isUnknownServerError: boolean
}

export const TopLinks: React.FC<TopLinksProps> = (props) => {
  const { isUserExists, isInvalidPromo, isUnknownClientError, isUnknownServerError, isOrganizationExists } = props;
  return (
    <VelocityTransitionGroup
      enter={{ animation: "slideDown" }}
      leave={{ animation: "slideUp" }}
    >
      {isUserExists && (
        <div className="qpos-login-alert">{i18n.messages.userExists}</div>
      )}

      {isInvalidPromo && (
        <div className="qpos-login-alert">
          {i18n.messages.invalidPromo}
        </div>
      )}

      {isUnknownClientError && (
        <div className="qpos-login-alert">
          {i18n.messages.unknownClientError}
        </div>
      )}

      {isUnknownServerError && (
        <div className="qpos-login-alert">
          {i18n.messages.unknownServerError}
        </div>
      )}

      {isOrganizationExists && (
        <div className="qpos-login-alert">
          {i18n.messages.isOrganizationExists}
        </div>
      )}
    </VelocityTransitionGroup>
  )
}