import React from 'react';
// @ts-ignore
import logo from './starrys-logo.svg'

export const Brand = () => {
  return (
    <div className="brand" style={{ marginBottom: '28px' }}>
      <img
        className="brand-img"
        src={logo}
        alt='Кассатка'
        style={{ width: '40%' }}
      />
    </div>
  );
}