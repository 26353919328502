import { postFetch } from '../../api'

type RegisterPartnerParams = {
  email: string
  become_partner: true
  partner_network_mode: 'mode2'
}

type RegisterPartnerResult = {
  token: string
  type: string
  brand?: string
}

export async function registerPartnerApi(params: RegisterPartnerParams): Promise<RegisterPartnerResult> {
  return await postFetch<RegisterPartnerParams, RegisterPartnerResult>('/register', params)
}