import React from "react";

import * as Components from "../../components";
import * as validators from "../../utils/validators";
import { Links, RecoverForm } from './_Components'
import { AuthError, isResponseWithAuthError } from '../../api'
import * as selectors from '../../utils/errorDecoders'
import { recoverApi } from "./_api";

type RecoverPageProps = {

}

type RecoverPageState = {
  email: string
  error: AuthError | null
  isRecoverEmailSent: boolean
}

export class RecoverPage extends React.Component<RecoverPageProps, RecoverPageState> {
  constructor(props: RecoverPageProps) {
    super(props);
    this.state = {
      email: '',
      error: null,
      isRecoverEmailSent: false,
    };
  }

  recover = async () => {
    const { email } = this.state;
    let submitIsEnabled = validators.isEmail(email);
    if (!submitIsEnabled) return;

    try {
      this.setState({ error: null });
      const result = await recoverApi({ email })
      if (result.info_code) {
        this.setState({ isRecoverEmailSent: result.info_code === 'email_sent' })
      }
    } catch (e: any) {
      if (isResponseWithAuthError(e)) {
        this.setState({ error: e.authError })
      }
    } finally {
      this.setState({ email: '' });
    }
  }

  render() {
    const { email, error, isRecoverEmailSent } = this.state;
    const submitIsEnabled = validators.isEmail(email);
    const isUnknownClientError = selectors.isUnknownClientError(error);
    const isUnknownServerError = selectors.isUnknownServerError(error);
    const isUnknownEmail = selectors.isUnknownEmail(error);
    const isUserNotFound = selectors.isUserNotFound(error);

    return (
      <div className="app">
        <div className="panel-body">
          <Components.Brand />
          <RecoverForm
            recover={this.recover}
            onChangeEmail={(email: string) => this.setState({ email })}
            isRecoverEmailSent={isRecoverEmailSent}
            isUnknownEmail={isUnknownEmail}
            isUnknownClientError={isUnknownClientError}
            isUnknownServerError={isUnknownServerError}
            isUserNotFound={isUserNotFound}
            submitIsEnabled={submitIsEnabled}
            email={email}
          />
          <Links />
        </div>
      </div>
    );
  }
}