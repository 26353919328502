import fetch from 'isomorphic-fetch';
import config from './config';

const { baseUrl, headers } = config;

export type AuthError = {
  error: string
  message: string
  statusCode: number
  status?: number
  validation?: {
    keys?: ReadonlyArray<'email' | 'password'>
  }
}

export type ResponseWithAuthError = Error & {
  authError: AuthError
}

export function isResponseWithAuthError(v: any): v is ResponseWithAuthError {
  return !!v.authError
}

export async function postFetch<Body, Result>(url: string, params: Body): Promise<Result> {
  const urlWithBaseUrl = `${baseUrl}${url}`;
  const response = await fetch(urlWithBaseUrl, { method: 'POST', headers, body: JSON.stringify(params) });

  if (response.status >= 400) {
    const errorResult: AuthError = await response.json();
    const error: ResponseWithAuthError = new Error(errorResult.message) as ResponseWithAuthError;
    error.authError = errorResult;
    throw error;
  }

  return await response.json();
}