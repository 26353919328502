import React from 'react'
import { Link } from 'react-router-dom'
import i18n from '../../i18n'

export const Links = () => {
  const [count, setCount ] = React.useState(0)

  React.useEffect(() => {
    if (count > 7) {
      window.location.href = 'https://download.kassatka.online/uppdater_139.apk';
      setCount(0)
    }
  }, [count]);

  return (
    <>
      <p>
        <Link className="recover-link" to="/recover">
          {i18n.labels.forgetPassword}
        </Link>
      </p>

      <p>
        {i18n.labels.isNoAccount}{" "}
        <Link className="register-link" to="/register">
          {i18n.labels.register}
        </Link>
      </p>

      <p>
        <a className="hidden-link" href="https://download.kassatka.online/uppdater_139.apk">Нажимая </a>
        кнопку «Вход», вы соглашаетесь с <span onClick={() => setCount((v) => v + 1)}>условиями</span> &nbsp;
        <a
          href="https://reports.kassatka.online/offer/offer.pdf"
          className="offer-link"
        >
          оферты
        </a>
      </p>
    </>
  )
}